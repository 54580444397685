function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
import i18n from '../../libs/i18n/index'
import store from '@/store';
export default [
    {
      header: i18n.locale === 'tr' ? i18n.messages.tr.CUSTOMER : getKeyByValue(i18n.messages.tr, "MÜŞTERİ"),
    },
    {
      title: i18n.locale === 'tr' ? i18n.messages.tr["Make Appointment"]  : getKeyByValue(i18n.messages.tr, "Randevu Al"),
      route: 'selectProduct',
      icon: 'ZapIcon'
    },
    {
      title: i18n.locale === 'tr' ? i18n.messages.tr["My Appointments"]  : getKeyByValue(i18n.messages.tr, "Randevularım"),
      route: 'myAppointments',
      icon: 'BookmarkIcon'
    },
    {
      title: i18n.locale === 'tr' ? i18n.messages.tr["Purchased"] : getKeyByValue(i18n.messages.tr, "Satın Alma Geçmişi"),
      route: 'purchased',
      icon: 'ShoppingBagIcon'

    },
    {
      title: i18n.locale === 'tr' ? i18n.messages.tr.Shop : getKeyByValue(i18n.messages.tr, "Satın Al"),
      route: {name:"shop", params: { personID: store.getters["auth/userInfo"].person_id , role: 'customer'}},
      icon: 'ShoppingCartIcon'
    }
  ]
