<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © <b>{{ computedVersion }}</b> <b-link
        class="ml-25"
        href="https://www.fintechyazilim.com"
        target="_blank"
    ><b>Fintech Yazılım</b></b-link>
      <span class="d-none d-sm-inline-block"> , All rights Reserved</span>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

const jsonFile = require('../../../../package.json')

export default {
  components: {
    BLink,
  },
    computed: {
      computedVersion(){
          return jsonFile.version
      }
    }
}
</script>
