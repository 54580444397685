<template>
  <b-row
    v-if="breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item :to="{ name: 'apps-todo' }">
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Todo</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col> -->
  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  data(){
    return{
      pageTitle:'',
      name:'',
      productName: ''
    }
  },
  computed:{
    breadcrumb(){
      if(this.$route.meta.breadcrumb){
        //studioLicence/:studioID
        if(this.$route.name === 'studio_licence'){
          this.getStudioName('Licence')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if (this.$route.name === 'studio_edit'){
          this.getStudioName('Edit')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if (this.$route.name === 'studio_user_list'){
          this.getStudioName('User List')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if(this.$route.name === 'activityPriceList'){
          this.getActivityName('Price List')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if(this.$route.name === 'AddActivityToTrainer'){
          this.getTrainerName('Trainer')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if(this.$route.name === 'CreateRoomCapacity'){
          //route parametreleri içinde roomName bilgisi olduğundan dolayı
          this.$route.meta.breadcrumb[1].text = this.$route.params.roomName + ' ' + this.$t('Capacity')
        } else if(this.$route.name === 'AddProductToTrainer'){
          // this.getTrainerName('Assign Product to')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if(this.$route.name === 'InstructorProducts') {
          this.getTrainerName('Instructor Products')
          this.$route.meta.breadcrumb[1].text = this.name
        } else if(this.$route.name === 'productPriceList'){
          this.getTrainerName('Instructor Products')
          this.$route.meta.breadcrumb[0].text = this.name;
          this.$route.meta.breadcrumb[0].to = `/InstructorProducts/${this.$route.params.trainerID}`
          this.getTrainerProductName('Price History')
          this.$route.meta.breadcrumb[1].text = this.productName
        } else if(this.$route.name === 'facility_user_edit'){
          this.$route.meta.breadcrumb[0].to = `/studioUserList/${this.$route.params.studioID}` //ilgili tesisin kullanıcılarına geri dönmek için
        }

        // console.log("----------");
        // console.log(this.$route.meta);
        // console.log("----------");
        // console.log(this.$route);
        return this.$route.meta.breadcrumb
      } else{
      }
    }
  },
  methods: {
    //Facilities için
    async getStudioName(transaction_param){
      await this.$database.DistributorService.get_studio_name(
              this.$route.params.studioID //studioID parametresi
            ).then(res => {
              if(res.is_success){
                this.name = res.result.name + ' ' + this.$t(transaction_param)//ex: 1HR Compression Therapy (Edit/Licence)
              }
          })
    },
    //Activites için
    async getActivityName(transaction_param){
      await this.$database.StudioService.get_activity_name(
              this.$route.params.activityID
      ).then(res => {
        if(res.is_success){
          this.name = res.result.name + ' ' + this.$t(transaction_param)//ex: Combat
        }
      })
    },
    //Trainers için
    async getTrainerName(transaction_param){
      await this.$database.StudioService.get_trainer_name(
        this.$route.params.trainerID //person_id geliyor aslında...
      ).then(res => {
        if(res.is_success){
          this.name =  this.$t(transaction_param) + ' ' + res.result.name + ' ' + res.result.last_name //ex: Melih Çelik Aktivite Ekle
        }
      })
    },
    //Trainer ürünü için
    async getTrainerProductName(transaction_param){
      await this.$database.StudioService.get_trainer_product_name(
        this.$route.params.productID
      ).then(res => {
        if(res.is_success){
          this.productName = this.$t(transaction_param) +  ': ' + res.result.title
        }
      })
    }
  },
  mounted(){
    // console.log("meta");
    // console.log(this.$route.meta.breadcrumb);
  }
}
</script>
