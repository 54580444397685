
function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
import i18n from '../../libs/i18n/index'
export default [
  {
    header: 'AVV',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr.Facilities : getKeyByValue(i18n.messages.tr, "Tesisler"),
    route: 'studio_list',
    icon: 'HomeIcon',
  },
  // {
  //   title: i18n.locale === 'tr' ? i18n.messages.tr.Parameters : getKeyByValue(i18n.messages.tr, "Parametreler"),
  //   route: 'parameters',
  //   icon: 'Share2Icon',
  // },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["User Groups"] : getKeyByValue(i18n.messages.tr, "Kullanıcı Grupları"),
    route: 'permission',
    icon: 'SlidersIcon',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["User List"] : getKeyByValue(i18n.messages.tr, "Kullanıcı Listesi"),
    route: 'distributor_list',
    icon: 'GlobeIcon',
  },
]
