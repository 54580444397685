function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}
import i18n from '../../libs/i18n/index'
export default [
  {
    header: i18n.locale === 'tr' ? i18n.messages.tr.FACILITY : getKeyByValue(i18n.messages.tr, "TESİS"),
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr.Customers : getKeyByValue(i18n.messages.tr, "Müşteriler"),
    route: 'customerList',
    icon: 'UsersIcon',
  },
  // Aktiviteler (activityList) old route
  // {
  //   title: i18n.locale === 'tr' ? i18n.messages.tr.Activities : getKeyByValue(i18n.messages.tr, "Aktiviteler"),
  //   route: 'activityList',
  //   icon: 'WatchIcon',
  // },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr.Rooms : getKeyByValue(i18n.messages.tr, "Salonlar"),
    route: 'studio_room_list',
    icon: 'HomeIcon',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr.Appointments : getKeyByValue(i18n.messages.tr, "Randevular"),
    route: 'customerAppointment',
    icon: 'CalendarIcon',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["Trainers"] : getKeyByValue(i18n.messages.tr, "Eğitmenler"),
    route: 'studio_instructors_list',
    icon: 'CircleIcon',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["Activities"] : getKeyByValue(i18n.messages.tr, "Aktiviteler"),
    route: 'activityList',
    icon: 'WatchIcon',
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["Scheduler"] : getKeyByValue(i18n.messages.tr, "Takvim Planla"),
    route: 'appointment-calendar',
    icon: 'CalendarIcon'
  },
  // {
  //   title: i18n.locale === 'tr' ? i18n.messages.tr["Set Your Availability"] : getKeyByValue(i18n.messages.tr, "Müsaitliği Ayarla"),
  //   route: 'avaibleTime',
  //   icon: 'CalendarIcon'
  // },
  // {
  //   title: i18n.locale === 'tr' ? i18n.messages.tr["Set Your Availability"] : getKeyByValue(i18n.messages.tr, "Müsaitliği Ayarla"),
  //   route: 'selectTrainer',
  //   icon: 'CalendarIcon'   
  // },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["All Products"] : getKeyByValue(i18n.messages.tr, "Tüm Ürünler"),
    route: 'products',
    icon: 'ShoppingBagIcon'
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["Products Sold"] : getKeyByValue(i18n.messages.tr, "Satılan Ürünler"),
    route: 'products-sold',
    icon: 'ShoppingCartIcon'
  },
  {
    title: i18n.locale === 'tr' ? i18n.messages.tr["Consumed Activities"] : getKeyByValue(i18n.messages.tr, "Tüketilen Activiteler"),
    route: 'consumedActivities',
    icon: 'UserMinusIcon'
  },
  {
    title: 'QR Code',
    route: 'qrcode',
    icon: 'SquareIcon',
  },
  {
    title: 'Ayarlar',
    icon: 'SettingsIcon',
    children: [
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Define Entrance Door"] : getKeyByValue(i18n.messages.tr, "Define Entrance Door"),
        route: 'StudioDoorCreate',
        icon: 'LayoutIcon'
      },
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Confirm User"] : getKeyByValue(i18n.messages.tr, "Confirm User"),
        route: 'CustomerManualActivate',
        icon: 'PlusSquareIcon'
      },
    ]
  },
  {
    title: 'Raporlar', //i18n.locale === 'tr' ? i18n.messages.tr.Settings  : getKeyByValue(i18n.messages.tr, "Raporlar"),
    icon: 'ClipboardIcon',
    children: [
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Participation Amount"] : getKeyByValue(i18n.messages.tr, "Katılım  Miktarı"),
        route: 'AttendanceByTimePeriod',
        icon: 'SettingsIcon',
      },
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Packages Sold"] : getKeyByValue(i18n.messages.tr, "Satılan Paketler"),
        route: 'PackagesSold',
        icon: 'SettingsIcon',
      },
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Number of Customer Activity"] : getKeyByValue(i18n.messages.tr, "Number of Customer Activity"),
        route: 'CustomerActivityCount',
        icon: 'SlackIcon',
      },
      {
        title: i18n.locale === 'tr' ? i18n.messages.tr["Number of Trainer Activity"] : getKeyByValue(i18n.messages.tr, "Number of Trainer Activity"),
        route: 'TrainerActivityCount',
        icon: 'SlackIcon',
      }
    ],
  }
]